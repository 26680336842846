var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.consent",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-center"},[(item.loading)?_c('v-progress-circular',{staticClass:"ml-1",attrs:{"indeterminate":"","size":"16","width":"2"}}):_c('v-tooltip',{key:item.id + item.consent,attrs:{"bottom":"","disabled":!item.consent},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"value":!!item.consent,"disabled":!!item.consent},on:{"input":function($event){return _vm.consent(item)}}},'v-simple-checkbox',attrs,false),on))]}}],null,true)},[(item.consent)?_c('span',[_vm._v(_vm._s(_vm.$t('agreements.acceptedOn'))+" "),_c('BaseDate',{attrs:{"date":item.consent.created_at}})],1):_vm._e()])],1)]}},{key:"item.valid_since",fn:function(ref){
var item = ref.item;
return [_c('BaseDate',{attrs:{"date":item.valid_since}})]}},{key:"item.accepted_at",fn:function(ref){
var item = ref.item;
return [(item.consent)?_c('span',[_c('BaseDate',{attrs:{"date":item.consent.created_at}})],1):_c('span',[_vm._v(_vm._s(_vm.$t('agreements.NA')))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.consent)?_c('v-btn',{staticClass:"my-3 mr-3",attrs:{"loading":item.loading,"dark":""},on:{"click":function($event){return _vm.consent(item)}}},[_vm._v(" "+_vm._s(_vm.$t('agreements.accept'))+" ")]):_vm._e(),_c('v-btn',{staticClass:"my-3 mr-3",attrs:{"to":{ name: 'Agreement', params: { id: item.id, back: true } },"dark":""}},[_vm._v(" "+_vm._s(_vm.$t('agreements.view'))+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
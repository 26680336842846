<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    disable-pagination
    hide-default-footer
  >
    <template #[`item.consent`]="{ item }">
      <span class="text-center">
        <v-progress-circular
          v-if="item.loading"
          indeterminate
          size="16"
          width="2"
          class="ml-1"
        />

        <v-tooltip
          v-else
          :key="item.id + item.consent"
          bottom
          :disabled="!item.consent"
        >
          <!-- ↑ key is used to refresh hover state and show tooltip on change -->
          <template #activator="{ on, attrs }">
            <v-simple-checkbox
              :value="!!item.consent"
              :disabled="!!item.consent"
              v-bind="attrs"
              @input="consent(item)"
              v-on="on"
            />
          </template>
          <span v-if="item.consent">{{ $t('agreements.acceptedOn') }} <BaseDate :date="item.consent.created_at"/></span>
        </v-tooltip>
      </span>
    </template>
    <template #[`item.valid_since`]="{ item }">
      <BaseDate :date="item.valid_since"/>
    </template>
    <template #[`item.accepted_at`]="{ item }">
      <span v-if="item.consent"><BaseDate :date="item.consent.created_at"/></span>
      <span v-else>{{ $t('agreements.NA') }}</span>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn v-if="!item.consent" :loading="item.loading" class="my-3 mr-3" dark
             @click="consent(item)"
      >
        {{ $t('agreements.accept') }}
      </v-btn>
      <v-btn :to="{ name: 'Agreement', params: { id: item.id, back: true } }" class="my-3 mr-3" dark>
        {{ $t('agreements.view') }}
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "AgreementList",
  data() {
    return {
      headers: [
        { text: this.$t('agreements.name'),        value: 'name',        sortable: false },
        { text: this.$t('agreements.validSince'),  value: 'valid_since', sortable: false },
        { text: this.$t('agreements.acceptedOn'),  value: 'accepted_at', sortable: false },
        { text: '',                                value: 'actions',     sortable: false },
      ],
      items: [],
      loading: false,
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.loading = true
      this.axios
        .get(`/agreements/`)
        .then((response) => {
          this.loading = false
          this.items = response.data.results
          /* TODO consents to old agreements
          for (const agreement of response.data) {
            if (!this.agreements[agreement.category]) {
              this.agreements[agreement.category] = []
            }
            this.agreements[agreement.category].push(agreement)
          }
          */
        })
        .catch(() => {
          this.loading = false
        })
    },
    consent(agreement) {
      this.$set(agreement, 'loading', true)
      this.axios
        .post(`/agreements/${agreement.id}/consent/`)
        .then(() => {
          agreement.loading = false
          this.fetch()
        })
        .catch(() => {
          agreement.loading = false
        })
    },
  },
})
</script>

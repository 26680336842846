<template>
  <Container :title="$t('settingsPage.pageTitle')">
    <div class="inside-container">
      <h3 class="title text-uppercase">{{ $t('settingsPage.userAccountInfo') }}</h3>
      <UserInfoList :primary-rows="rows.primary"/>

      <v-card flat class="mb-12">
        <h3 id="agreements" class="title text-uppercase mt-16">{{ $t('settingsPage.portalAgreements') }}</h3>
        <AgreementList/>
      </v-card>

      <v-card flat class="mb-12">
        <h3 class="title text-uppercase mb-2 mt-12">{{ $t('settingsPage.emailNotifications') }}</h3>
        <v-list class="user-notifications">
          <v-list-item v-for="row in notifications" :key="row.key">
            <v-list-item-content>
              <v-col cols="12" md="5">
                <v-list-item-subtitle v-text="row.label"/>
              </v-col>
              <v-col cols="12" md="7">
                <v-list-item-title class="switch-row">
                  <v-switch
                    v-model="user[row.key]"
                    :label="user[row.key] ? $t('settingsPage.yes') : $t('settingsPage.no')"
                    :loading="loadingNotifications[row.key]"
                    @change="updateNotifications(row.key, user[row.key])"
                  />
                </v-list-item-title>
              </v-col>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card flat class="mb-12">
        <h3 class="title text-uppercase">{{ $t('settingsPage.portalPreferences') }}</h3>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-col cols="12" md="5">
                <v-list-item-subtitle v-text="$t('settingsPage.darkMode')"/>
              </v-col>
              <v-col cols="12" md="7">
                <v-list-item-title class="switch-row">
                  <v-switch
                    v-model="user['dark_mode']"
                    :label="user['dark_mode'] ? $t('common.on') : $t('common.off')"
                    :loading="loadingNotifications['dark_mode']"
                    @change="updateNotifications('dark_mode', user['dark_mode'])"
                  />
                </v-list-item-title>
              </v-col>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </Container>
</template>

<script>
import AgreementList from "@/modules/documents/components/AgreementList.vue"

import Vue from 'vue'
import UserInfoList from "../components/UserInfoList.vue"

export default Vue.extend({
  name: "Settings",
  components: {
    AgreementList,
    UserInfoList,
  },
  data() {
    return {
      loadingPasswordReset: false,
      loadingNotifications: {},
      showAdditionalInfo: false,
    }
  },
  computed: {
    rows() {
      return {
        primary: [
          { key: 'name',        label: this.$t('settingsPage.name') },
          { key: 'email',       label: this.$t('settingsPage.email') },
          { key: 'password',    label: this.$t('settingsPage.password') },
        ],
        secondary: [
          { key: 'birthdate',   label: this.$t('settingsPage.birthdate') },
          { key: 'phone',       label: this.$t('settingsPage.phone') },
          { key: 'address',     label: this.$t('settingsPage.address') },
          { key: 'national_id', label: this.$t('settingsPage.nationalIdNumber') },
          { key: 'country',     label: this.$t('settingsPage.countryOfResidence') },
          { key: 'doc_country', label: this.$t('settingsPage.documentIssuingCountry') },
          { key: 'doc_num',     label: this.$t('settingsPage.documentNumber') },
          { key: 'is_exposed',  label: this.$t('settingsPage.politicallyExposedPerson') },
          { key: 'activity',    label: this.$t('settingsPage.fieldOfEconomicalActivity') },
        ]}
    },
    notifications() {
      return [
        { key: 'notify_contract_updates',    label: this.$t('settingsPage.loanContractUpdates') },
        { key: 'notify_transaction_summary', label: this.$t('settingsPage.dailyTransactionSummary') },
        { key: 'subscribe_newsletters',      label: this.$t('settingsPage.newslettersAndOtherDirectDarketing') },
        { key: 'subscribe_sales_requests',   label: this.$t('settingsPage.saleRequests') },
      ]
    },
    currentInvestAccount() {
      return this.$store.state.account
    },
    user() {
      return this.$auth.user()
    },
  },
  methods: {
    updateNotifications(type, value) {
      this.$set(this.loadingNotifications, type, true)
      this.$http
        .patch(`/auth/users/me/`, { [type]: value })
        .then(() => {
          this.loadingNotifications[type] = false
        })
        .catch(() => {
          this.loadingNotifications[type] = false
        })
    },
    resetPassword() {
      this.loadingPasswordReset = true
      this.$http
        .post(`/auth/users/reset_password/`, { email: this.user.email })
        .then(() => {
          this.loadingPasswordReset = false
          this.$store.commit('pushMessage', {
            icon: 'mdi-check',
            text: this.$t('infoMessages.newPasswordHasBeenSent'),
          })
        })
        .catch(() => {
          this.loadingPasswordReset = false
        })
    },
  },
})
</script>

<style lang="scss" scoped>
.user-info::v-deep .v-list-item {
  min-height: 0 !important;
}

.user-info::v-deep .v-list-item__content {
  padding: 4px !important;
}

::v-deep .button-row {
  margin: -10px 0; /* allow the button to overlap other row paddings */
}

::v-deep .switch-row {
  margin: -25px; /* allow switches to overlap other row paddings */
  padding-left: 30px;
}
</style>
